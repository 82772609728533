<template>
  <a-card>
    发车日期：
    <a-date-picker
      class="distance dis"
      format="YYYY-MM-DD"
      v-model="filter.cdDay"
      @change="chengeDate"
    />
    <a-button type="primary" @click="chengeDate">查询</a-button>
    <div class="flex">
      <div style="width: 450px; margin-right: 20px;">
        <div class="ant-table-wrapper">
          <div
            class="ant-table ant-table-fixed-header ant-table-scroll-position-left ant-table-layout-fixed ant-table-default"
          >
            <div class="ant-table-body" style="overflowy: scroll;">
              <table style="width: 450px;">
                <thead class="ant-table-thead">
                  <tr>
                    <th style="width: 60px;">序号</th>
                    <th style="width: 150px;">线路名称</th>
                    <th>人数</th>
                    <th>操作</th>
                  </tr>
                </thead>
                <tbody class="ant-table-tbody">
                  <tr v-for="(item, index) in appointList" :key="item.id">
                    <td style="width: 60px;">{{ index + 1 }}</td>
                    <td style="width: 170px;">
                      {{ item.depotNameStart }}
                      <br />
                      到
                      <br />
                      {{ item.depotNameEnd }}
                    </td>
                    <td>{{ item.totalPassengerNum }}</td>
                    <td>
                      <a-button @click="agginCar(item)" type="link">
                        派车
                      </a-button>
                      <br />
                      <a-button
                        type="link"
                        style="margin-top: 10px;"
                        @click="serDeatil(index)"
                      >
                        已派车辆
                      </a-button>
                    </td>
                  </tr>
                  <tr v-if="!appointList.length">
                    <td class="no-data" colspan="11">暂无数据</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="pagination-container">
          <a-pagination
            :pageSize="this.pageSize"
            :total="total"
            @change="changePage"
          />
        </div>
      </div>
      <div class="flex1" v-if="index">
        <div class="ant-table-wrapper">
          <div
            class="ant-table ant-table-fixed-header ant-table-scroll-position-left ant-table-layout-fixed ant-table-default"
          >
            <div class="ant-table-body" style="overflowy: scroll;">
              <table style="width: 100%; min-width: 600px;">
                <thead class="ant-table-thead">
                  <tr>
                    <th>车牌</th>
                    <th>司机</th>
                    <th>联系电话</th>
                    <th>出发时间</th>
                    <th>人数</th>
                    <th>状态</th>
                    <th>操作</th>
                  </tr>
                </thead>
                <tbody class="ant-table-tbody">
                  <tr v-for="item in indexList" :key="item.id">
                    <td>
                      {{ item.busNumber ? item.busNumber : '待派车' }}
                      {{ item.busNumber ? `(${item.seatNum}座)` : '' }}
                    </td>
                    <td>{{ item.driverName }}</td>
                    <td>{{ item.driverCell }}</td>
                    <td>
                      {{ item.departureDay }}&nbsp;&nbsp;{{ item.startTime }}
                    </td>
                    <td>
                      <a-button type="primary" @click="serSpotPag(item)">
                        {{ item.passengerNum }}人
                      </a-button>
                    </td>
                    <td>
                      <span v-if="item.busStatusId == 0">等待发车</span>
                      <span v-if="item.busStatusId == 1">已发车</span>
                      <span v-if="item.busStatusId == 2">已到站</span>
                      <span v-if="item.busStatusId == 3">已离站</span>
                      <span v-if="item.busStatusId == 4">结束</span>
                    </td>
                    <td>
                      <a-button @click="cancelCar(item)" v-if="item.busPlanId">
                        取消派车
                      </a-button>
                    </td>
                  </tr>
                  <tr v-if="!appointList.length">
                    <td class="no-data" colspan="11">暂无数据</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    派车-->
    <a-modal
      title="指定班车"
      @ok="addappointList"
      :visible="visible"
      @cancel="visible = false"
      okText="确定"
    >
      <div class="addrole">
        <p class="flex">
          <label for="">选择司机</label>
          <a-select
            class="flex1"
            v-model="addCarDriver.driverId"
            placeholder="请选择司机"
          >
            <a-select-option
              :value="item.userId"
              v-for="item in driverList"
              :key="item.userId"
            >
              {{ item.userName }} -- {{ item.roleName }}
            </a-select-option>
          </a-select>
        </p>
        <p class="flex">
          <label for="">派车时间</label>
          <a-time-picker format="HH:mm" v-model="startTime" />
        </p>
        <p class="flex">
          <label for="">选择车辆</label>
          <a-select
            class="flex1"
            v-model="addCarDriver.busId"
            placeholder="请选择车辆"
          >
            <a-select-option
              :value="item.id"
              v-for="item in carList"
              :key="item.id"
            >
              {{ item.carNumber }}--{{ item.seatNum }}座
            </a-select-option>
          </a-select>
        </p>
      </div>
    </a-modal>
    <a-modal
      title="已约学员"
      :visible="showSpot"
      @cancel="showSpot = false"
      okText="确定"
      :width="700"
    >
      <table class="my-table">
        <tr>
          <th>序号</th>
          <th>站点名称</th>
          <th>已约学员</th>
          <th>未上车</th>
          <th style="width: 80px;">学员</th>
          <th style="width: 160px;">身份证</th>
          <th style="width: 80px;">状态</th>
        </tr>
        <tr v-for="(item, index) in spotPager" :key="item.depotId">
          <td>{{ index + 1 }}</td>
          <td>{{ item.depotName }}</td>
          <td>{{ item.passengerNumTotal }}</td>
          <td>{{ item.passengerNumRemain }}</td>
          <td colspan="5">
            <table style="width: 100%; border: none;">
              <tr
                v-for="pag in item.list"
                style="border-bottom: solid 1px rgba(0, 0, 0, 0.1);"
                :key="pag.reserveId"
              >
                <td style="border: none; width: 80px;">
                  {{ pag.passengerName }}
                </td>
                <td
                  style="
                    width: 160px;
                    border: none;
                    border-left: solid 1px rgba(0, 0, 0, 0.1);
                  "
                >
                  {{ pag.passengerIdCard }}
                </td>
                <td style="width: 80px;">
                  <span v-if="pag.passengerStatus == 1">已约</span>
                  <span v-if="pag.passengerStatus == 2">取消</span>
                  <span v-if="pag.passengerStatus == 3">爽约</span>
                  <span v-if="pag.passengerStatus == 4">乘坐</span>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </a-modal>
  </a-card>
</template>
<script>
import moment from 'moment'
export default {
  components: {},
  data() {
    return {
      index: null,
      indexList: [],
      startTime: null,
      showSpot: false,
      visible: false,
      filter: {
        cdDay: '',
        pageNum: 1,
        pageSize: this.pageSize,
      },
      appointList: [{}],
      addCarDriver: {
        busLineId: '',
        departureDay: '',
        driverId: '',
        busId: '',
        startTime: null,
      },
      total: 0,
      action: {
        add: false,
      },
      driverList: [],
      carList: [],
      spotPager: [],
      userList: [],
    }
  },
  methods: {
    serDeatil(i) {
      this.index = String(i)
      this.indexList = this.appointList[i].details
    },
    cancelCar(item) {
      let _this = this
      this.$confirm({
        title: '是否确认取消班车？',
        content: '取消班车之后，需要重新派车！',
        onOk: () => {
          _this
            .$post(this.$url.取消派车, {
              busPlanId: item.busPlanId,
            })
            .then((data) => {
              _this.getAppointList()
              _this.$tips('取消成功', '已取消派车，请重新分配车辆！', 'success')
            })
        },
      })
    },
    serSpotPag(item) {
      this.showSpot = true
      this.getSpotPager(item)
    },
    //获取站点上学员预约情况
    getSpotPager(item) {
      this.$post(this.$url.预约学员信息, {
        busLineId: item.busLineId,
        busTimesId: item.busTimesId,
        busPlanId: item.busPlanId,
        departureDay: this.filter.cdDay,
      }).then((data) => {
        this.spotPager = data.data.list
      })
    },
    chengeDate() {
      this.filter.pageNum = 1
      this.getAppointList()
    },
    //司机列表
    getDriverList() {
      this.$post(this.$url.班车用户列表, {})
        .then((data) => {
          this.driverList = data.data.list
        })
        .catch(() => {})
    },
    getCarList() {
      this.$post(this.$url.班车车辆列表, {}).then((data) => {
        this.carList = data.data.list
      })
    },
    agginCar(item) {
      this.addCarDriver.busLineId = item.busLineId
      this.addCarDriver.startTime = item.startTime
      this.addCarDriver.departureDay = item.departureDay
      this.visible = true
    },
    moment,
    disabledDateStart(startValue) {
      //return startValue && startValue <= moment().endOf("day");
      return startValue && startValue <= Date.now() - 8.64e7
    },
    getAppointList() {
      if (!this.filter.cdDay) {
        this.$warning({
          okText: '确定',
          title: '警告',
          content: '选择日期后再查看对应的预约人数！',
          onOk() {},
        })
        return
      }
      this.filter.cdDay = moment(this.filter.cdDay).format('YYYY-MM-DD')
      this.$post(this.$url.班车约车人数显示, this.filter).then((data) => {
        this.appointList = data.data.list
        this.total = data.data.total
        this.indexList = []
      })
    },
    addNew() {
      this.addCarDriver = {
        beginDt: null,
        endDt: null,
        status: 1,
      }
      this.visible = true
    },
    addappointList() {
      this.addCarDriver.departureDay = this.filter.cdDay
      this.addCarDriver.startTime = moment(this.startTime).format('HH:mm')
      this.$post(this.$url.线路派车, this.addCarDriver).then(() => {
        this.$success({
          okText: '确定',
          title: '成功',
          content: '派车成功！',
          onOk() {},
        })
        this.addCarDriver = {
          busLineId: '',
          departureDay: '',
          driverId: '',
          busId: '',
          startTime: null,
        }
        this.startTime = null
        this.getAppointList()
        this.visible = false
      })
    },
    changePage(cur) {
      this.filter.pageNum = cur
      this.getAppointList()
    },
  },
  watch: {},
  mounted() {
    var date = new Date()
    this.filter.cdDay =
      date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
    this.getAppointList()
    this.getDriverList()
    this.getCarList()
  },
}
</script>
<style scoped>
.addrole p {
  margin: 15px 0px;
}
.addrole label {
  display: inline-block;
  width: 90px;
  text-align: right;
  padding-right: 10px;
  box-sizing: border-box;
}
.first-nav {
  line-height: 30px;
}
.second-nav {
  padding-left: 30px;
  line-height: 30px;
}
.shousuo {
  display: inline-block;
  width: 18px;
}
.second-nav span {
  display: block;
}
.edit-check {
  text-align: right;
  color: rgba(0, 0, 0, 0.5);
  float: right;
}
</style>
